import React, { Suspense, lazy } from "react";

import { client } from "./config/apollo";
import Loading from "./components/loading";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const Admin = lazy(() => import("./pages/admin"));
const SetPassword = lazy(() => import("./pages/setpassword"));

export default function Root() {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/admin" element={<Admin />} />
            <Route path="/set-password" element={<SetPassword />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ApolloProvider>
  );
}
