import React, { useEffect, useState } from "react";
import axios from "axios";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import {
  Box,
  Grid,
  Radio,
  Switch,
  Select,
  Button,
  Checkbox,
  TextField,
  Typography,
  RadioGroup,
  InputLabel,
  FormControl,
  Autocomplete,
  InputAdornment,
  FormControlLabel,
  CircularProgress,
  MenuItem,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import dayjs from "dayjs";
import _ from "lodash";
import { MuiChipsInput } from "mui-chips-input";
import ModalAlert from "./popup-alert.component";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { airportSiteSet } from "../config/data/airport";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { optionCategoryStatus } from "../config/data/status";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ClearIcon from "@mui/icons-material/Clear";
import CMSToast from "./toast.component";

import "dayjs/locale/en-gb";
const calRatio = ({ width, height, imageType, maxSizeInMB, errorMessage }) => {
  const findExpectRatio = imageType.split(":");
  const expectRatio = (findExpectRatio[0] / findExpectRatio[1]).toFixed(2);
  const imageRatio = (width / height).toFixed(2);
  if (expectRatio - imageRatio > -0.05 && expectRatio - imageRatio < 0.05) {
    return false;
  }
  return errorMessage
    .replace("{ratio}", `${findExpectRatio[0]} : ${findExpectRatio[1]}`)
    .replace("{size}", maxSizeInMB)
    .replace(/<br>/g, "\n");
};

export default function CMSInput(props) {
  const {
    value = "",
    label = "",
    labelFor = true,
    labelForUnder = false,
    labelText = label,
    labelTextUnder = "",
    endLabel = "",

    inputType = "text",
    error = false,
    required = false,
    condition = false,
    disableForm = false,
    fieldHeight = "78.8px",

    onChangeInput = () => {},
    onRowCountChange = () => {},
    onColumnCountChange = () => {},
    onDeleteFile = () => {},

    // For Selct input
    optionInput = [],
    optionStatus = [],
    disablePortal = true,
    disableClearable = false,

    // For file input
    fixedImageRatio = false,
    allowImageType = ".png, .jpg, .jpeg, .gif",
    allowFileType = ".csv, .xls, .xlsx",
    imageCkEditor = false,
    width = "calc(100%)",
    height = 300,
    minDateTime = true,
    maxFileSize = 2,
    fileWidth = 200,
    fileHeight = 200,
    defaultFileWidth = 200,
    defaultFileHeight = 200,
    maxFileSizeText = "(max. 2MB)",
    errorMessage = "Upload error. Please Check image ratio {ratio} and size (max {size}MB.)",
    // For set in case number
    minNumber = false,
    stepNumber = false,
    maxNumber = false,
  } = props;

  const today = dayjs();

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(value);
  const [rowCount, setRowCount] = useState(0);
  const [columnCount, setColumnCount] = useState(0);

  // for component case document and documentPrivilege

  useEffect(() => {
    if (
      !value ||
      value === "" ||
      (typeof value === "object" && value.file === null)
    ) {
      setFile(null);
      setLoading(false);
      setRowCount(0);
      setColumnCount(0);
    }
  }, [value]);

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    const maxFileSizeInMB = maxFileSize;
    const maxFileSizeInBytes = maxFileSizeInMB * 1024 * 1024;
    const allowedExtensions = [
      "text/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (selectedFile) {
      const fileExtension = selectedFile.type;
      const isAllowedExtension = allowedExtensions.includes(fileExtension);
      if (!isAllowedExtension) {
        ModalAlert({
          iconType: "error",
          title: "File type not allowed.",
          description:
            "Please upload a file with extension .csv, .xls, or .xlsx.",
        });
        return;
      }

      if (selectedFile.size > maxFileSizeInBytes) {
        ModalAlert({
          iconType: "error",
          title: `File size must not exceed ${maxFileSize} MB.`,
          description: "Please upload a smaller file.",
        });
        return;
      }
      setLoading(true);
      if (fileExtension === "text/csv") {
        Papa.parse(selectedFile, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            const validRows = result.data.filter((row) =>
              Object.values(row).some((value) => value !== "" && value !== null)
            );
            const rows = validRows.length;
            const columnsWithData = new Set();
            validRows.forEach((row) => {
              Object.entries(row).forEach(([key, value]) => {
                // Check if the column has any non-empty value
                if (value !== "" && value !== null && value !== undefined) {
                  columnsWithData.add(key);
                }
              });
            });

            const columnCount = columnsWithData.size;
            setColumnCount(columnCount);
            setRowCount(rows);
            onChangeInput(selectedFile);
            onRowCountChange(rows);
            onColumnCountChange(columnCount);
            setFile(selectedFile);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          },
          error: (error) => {
            console.error("Error parsing CSV:", error);
            setLoading(false);
          },
        });
      } else if (
        fileExtension === "application/vnd.ms-excel" ||
        fileExtension ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const binaryStr = event.target.result;
          const workbook = XLSX.read(binaryStr, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, {
            defval: "",
          });
          const validRows = jsonData.filter((row) =>
            Object.values(row).some((value) => value !== "" && value !== null)
          );
          const rows = validRows.length;
          const columnsWithData = new Set();
          validRows.forEach((row) => {
            Object.entries(row).forEach(([key, value]) => {
              if (value !== "" && value !== null && value !== undefined) {
                columnsWithData.add(key);
              }
            });
          });

          const columnCount = columnsWithData.size;
          setColumnCount(columnCount);
          setRowCount(rows);
          onRowCountChange(rows);
          onColumnCountChange(columnCount);
          onChangeInput(selectedFile);
          setFile(selectedFile);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        };
        reader.onerror = (error) => {
          console.error("Error reading file:", error);
          setLoading(false);
        };
        reader.readAsBinaryString(selectedFile);
      }
    }
  };

  const handleDeleteFile = () => {
    ModalAlert({
      iconType: "warning",
      title: "Are you sure you want to delete this file?",
      description: "Your imported file will be lost if you choose to continue.",
      cancelButton: true,
      textInCancelButton: "CANCLE",
      textInComfirmButton: "DELETE",
      onConfirmClick: () => {
        setFile(null);
        setLoading(false);
        onChangeInput("");
        onRowCountChange(1);
        onColumnCountChange(0);
        onDeleteFile();
        CMSToast({
          status: "success",
          title: "File deleted successfully.",
        });
      },
    });
  };

  const checkInputType = () => {
    switch (inputType) {
      case "text":
        return (
          <TextField
            label={label}
            fullWidth
            value={value}
            variant="outlined"
            onChange={(e) => {
              onChangeInput(e.target.value);
            }}
            disabled={disableForm}
            error={!!error}
          />
        );
      case "number":
        return (
          <TextField
            label={label}
            type="number"
            disabled={disableForm}
            fullWidth
            value={value}
            variant="outlined"
            onChange={(e) => {
              onChangeInput(e.target.value);
            }}
            error={error}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{endLabel}</InputAdornment>
              ),
            }}
            inputProps={{
              min: minNumber,
              step: stepNumber,
              max: maxNumber,
              onKeyDown: (e) => {
                if (e.key === "-" || e.key === "e") {
                  e.preventDefault();
                }
              },
            }}
          />
        );
      case "chip":
        return (
          <Box mt={1}>
            <MuiChipsInput
              label={label}
              value={Array.isArray(value) ? value : []}
              onChange={(e) => {
                if (!Array.isArray(e)) return;

                let temp = [];
                e.forEach((row) => {
                  temp.push(row.split(";"));
                });
                onChangeInput(_.uniq(_.flatten(temp)));
              }}
              fullWidth
              disabled={disableForm}
              error={error}
            />
          </Box>
        );

      case "document":
        return (
          <Box>
            <Box
              sx={{
                border: 1,
                width: width,
                height: height,
                borderRadius: 2,
                borderColor: error ? "red !important" : "",
                borderStyle: "dashed",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                cursor: "pointer",
                mt: 2,
                mb: 2,
              }}
            >
              {/* File upload button */}
              <Button
                component="label"
                className="center-item-wrapper"
                sx={{ flexDirection: "column", cursor: "pointer" }}
              >
                <UploadFileIcon className="text-color-blue" />
                <Typography className="text-color-blue">
                  Upload Document
                </Typography>
                <input
                  hidden
                  type="file"
                  accept={allowFileType}
                  disabled={disableForm}
                  onChange={handleFileUpload}
                />
              </Button>
            </Box>

            {/* Display the uploaded file info below the dashed box */}
            {file && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  border: "1px solid #E0E0E0",
                  padding: 1,
                  borderRadius: 2,
                  width: "fit-content",
                  maxWidth: 400,
                  mt: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {/* File icon and name */}
                <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
                  <img
                    src="https://cdn.sawasdee.ai/excel/v4yfetm0uroxo8.png"
                    alt="file icon"
                    style={{ width: 40 }}
                  />
                  <Typography
                    sx={{
                      ml: 2,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: "250px",
                    }}
                  >
                    {file.name}
                  </Typography>
                </Box>

                {/* Loading animation or delete button */}
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <ClearIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      if (!disableForm) {
                        handleDeleteFile();
                      } else return;
                    }}
                  />
                )}
              </Box>
            )}

            {/* Show allowed file types and size */}
            {!disableForm && !file && (
              <Box my={2}>
                <Typography
                  fontSize={14}
                >{`${allowFileType.toUpperCase()} (max. 2MB)`}</Typography>
              </Box>
            )}
          </Box>
        );

      case "documentPrivilege":
        return (
          <Box>
            {!file ? (
              <Box
                sx={{
                  border: 1,
                  width: width,
                  height: height,
                  borderRadius: 2,
                  borderColor: error ? "red !important" : "",
                  borderStyle: "dashed",
                  display: "flex",
                  cursor: "pointer",
                  color: "#0000001F",
                  mt: 2,
                  mb: 2,
                }}
              >
                <Button
                  component="label"
                  className="center-item-wrapper"
                  sx={{
                    flexDirection: "column",
                    cursor: "pointer",
                    display: "flex",
                    textTransform: "none",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <UploadFileIcon className="text-color-blue" />
                    <Typography
                      className="text-color-blue"
                      sx={{
                        ml: 2,
                        borderBottom: "1px solid currentColor",
                        display: "inline-block",
                      }}
                    >
                      Click to upload
                    </Typography>
                    <Typography sx={{ ml: 2, color: "#000000" }}>
                      Excel file (max. 3MB)
                    </Typography>
                  </Box>
                  <input
                    hidden
                    type="file"
                    accept={allowFileType}
                    disabled={disableForm}
                    onChange={handleFileUpload}
                  />
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  border: "1px solid #E0E0E0",
                  padding: 1,
                  borderRadius: 2,
                  width: "fit-content",
                  maxWidth: 400,
                  mt: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
                  <img
                    src="https://cdn.sawasdee.ai/excel/v4yfetm0uroxo8.png"
                    alt="file icon"
                    style={{ width: 40 }}
                  />
                  <Typography
                    sx={{
                      ml: 2,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: "250px",
                    }}
                  >
                    {file.name}
                  </Typography>
                </Box>

                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <ClearIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      if (!disableForm) {
                        handleDeleteFile();
                      } else return;
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
        );

        case "file":
          const raitio = fixedImageRatio
            ? fixedImageRatio.split(":")
            : "1:1".split(":");
  
          const expectRatio = raitio[0] / raitio[1];
          return (
            <Box>
              <Box
                sx={{
                  border: 1,
                  width: fileWidth,
                  height: fileHeight,
                  borderRadius: 2,
                  borderColor: error ? "red !important" : "",
                  borderStyle: "dashed",
                }}
                className="center-item-wrapper border-color-gray"
              >
                {value ? (
                  <img
                    src={value?.name ? URL.createObjectURL(value) : value}
                    style={{
                      height: !value ? defaultFileWidth : defaultFileHeight / expectRatio,
                      width: !value ? defaultFileWidth : defaultFileHeight * expectRatio,
                      maxHeight: defaultFileHeight,
                      maxWidth: defaultFileWidth,
                    }}
                    alt=""
                  />
                ) : (
                  <Button
  component="label"
  className="center-item-wrapper"
  sx={{
    flexDirection: "column",
    cursor: "pointer",
    minWidth: "100px",
    minHeight: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}
>
  <UploadFileIcon className="text-color-blue" />
  <Typography className="text-color-blue">Upload Image</Typography>
  <input
    hidden
    type="file"
    accept={allowImageType}
    disabled={disableForm}
    onChange={(e) => {
      let img = new Image();
      img.src = URL.createObjectURL(e.target.files[0]);
      const maxFileSizeInMB = maxFileSize;
      const maxFileSizeInKB = 1024 * 1024 * maxFileSizeInMB;
      if (e.target.files[0].size > maxFileSizeInKB) {
        // Check file size if more 2mb. Set width 0 to show Error
        img.width = 0;
      }
      const allowType = allowImageType.replace(/[^\w\s]/gi, "");
      if (!allowType.includes(e.target.files[0].type.split("/").pop())) {
        ModalAlert({
          iconType: "error",
          title: "Not allow this document type",
        });
        return;
      }
      img.onload = function handleLoad() {
        if (fixedImageRatio) {
          const notAllow = calRatio({
            width: img.width,
            height: img.height,
            imageType: fixedImageRatio,
            maxSizeInMB: maxFileSizeInMB,
            errorMessage: errorMessage,

          });
          if (notAllow) {
            ModalAlert({ iconType: "error", title: notAllow });
            return;
          }
        }

        onChangeInput(e.target.files[0]);
      };
    }}
  />
</Button>
                )}
              </Box>
              {!disableForm ? (
                value ? (
                  <Box my={1}>
                    <DeleteOutlineIcon onClick={() => onChangeInput("")} />
                  </Box>
                ) : (
                  <Box my={2}>
                  <Typography fontSize={14}>
                    {`${allowImageType.toUpperCase()} ${maxFileSizeText}`}
                  </Typography>
                  </Box>
                )
              ) : null}
            </Box>
          );
      case "select":
        const checkLabel =
          label || (condition === "userGroup" ? "User group" : false);

        return (
          <Autocomplete
            disablePortal
            value={optionInput.find((row) => row.value == value) || ""}
            options={optionInput}
            getOptionLabel={(option) => option?.label || ""}
            onChange={(e, newValue) => onChangeInput(newValue?.value || "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label={checkLabel || ""}
                error={!!error}
              />
            )}
            disabled={disableForm}
            isOptionEqualToValue={(option) => option.value === value || true}
            getOptionDisabled={(option) => option.disabled}
            disableClearable={disableClearable}
            renderOption={(props, option) => {
              return (
                <Box
                  {...props}
                  key={option.value}
                  sx={{
                    backgroundColor: "#ffffff !important",
                    "&:hover": {
                      background: "#f1f1f1 !important",
                    },
                  }}
                >
                  {option.label}
                </Box>
              );
            }}
          />
        );

      case "selectCheckbox":
        return (
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>{label}</InputLabel>
            <Select
              disabled={disableForm}
              multiple
              value={value}
              onChange={(e) => onChangeInput(e.target.value)}
              input={<OutlinedInput label={label} />}
              renderValue={(selected) => {
                return selected
                  .map((row) => {
                    return optionInput.find((rowFind) => {
                      return rowFind.value === row;
                    }).label;
                  })
                  .join(", ");
              }}
            >
              {optionInput.map((row) => (
                <MenuItem
                  key={row.label}
                  value={row.value}
                  disabled={disableForm}
                >
                  <Checkbox checked={value.indexOf(row.value) > -1} />
                  <ListItemText primary={row.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );

      case "selectStatus":
        // console.log("INPUTT", optionInput);
        const defineOptionStatus =
          optionStatus.length > 0 ? optionStatus : optionCategoryStatus;
        const statusOption = [...optionInput, ...defineOptionStatus];
        // console.log("DEFINE STATUS", defineOptionStatus);

        return (
          <Autocomplete
            value={statusOption.find(
              (row) => row.label == value || row.value == value
            )}
            disablePortal={false}
            disableClearable={true}
            options={statusOption}
            onChange={(e, newValue) => onChangeInput(newValue?.value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disabled={disableForm}
            getOptionLabel={(option) => option?.label || ""}
            renderInput={(params) => {
              const paramsValue = params.inputProps.value;
              const match = statusOption.find(
                (i) => i.label === paramsValue || i.value === paramsValue
              ); // For get status color
              return (
                <TextField
                  {...params}
                  error={error}
                  label={label}
                  sx={{
                    input: {
                      backgroundColor: match?.backgroundColor,
                      color: match?.textColor,
                      borderRadius: "16px",
                      textAlign: "center",
                      fontSize: "14px",
                      maxWidth: "60px",
                      px: 1,
                    },
                  }}
                />
              );
            }}
            renderOption={(props, option) => (
              <Box {...props} key={option.value}>
                <Typography
                  sx={{
                    backgroundColor: `${option?.backgroundColor}`,
                    color: `${option?.textColor}`,
                    borderRadius: "16px",
                    fontSize: "14px",
                    px: 1,
                  }}
                >
                  {option.label}
                </Typography>
              </Box>
            )}
          />
        );

      case "selectBadge":
        return (
          <Autocomplete
            disablePortal
            value={optionInput.find((row) => row.value == value) || ""}
            options={optionInput}
            onChange={(e, newValue) => onChangeInput(newValue?.value || "")}
            renderInput={(params) => <TextField {...params} label={label} />}
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  py: 1, // Add vertical padding to increase space between items
                }}
              >
                <Typography sx={{ flexGrow: 1 }}>{option.label}</Typography>
                <Typography
                  sx={{
                    ml: 2, // Add margin to separate status from label
                    fontSize: "0.8rem",
                    bgcolor: option.status === "Active" ? "#E8F5E9" : "#FAFAFA",
                    color: option.status === "Active" ? "#388E3C" : "#616161",
                    px: 1,
                    py: 0.5,
                    borderRadius: 1,
                  }}
                >
                  {option.status}
                </Typography>
              </Box>
            )}
            disabled={disableForm}
            isOptionEqualToValue={(option) => option.value === value || true}
            getOptionDisabled={(option) => option.disabled}
            disableClearable={disableClearable}
            ListboxProps={{
              sx: { maxHeight: "300px" }, // Adjust the max height of the dropdown
            }}
          />
        );

      case "switch":
        return (
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(value)}
                onChange={(e) => onChangeInput(e.target.checked)}
                disabled={disableForm}
                error={error}
              />
            }
            label={label}
          />
        );
      case "switchSubCat":
        return (
          <Box position={"absolute"} left={"26%"} marginTop={"8%"}>
            <Switch
              checked={Boolean(value)}
              onChange={(e) => onChangeInput(e.target.checked)}
              disabled={disableForm}
              error={error}
            />
            <span>{value ? "Active" : "Inactive"}</span>
          </Box>
        );

      case "dateTime":
        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={"en-gb"}
          >
            <DemoContainer
              components={["DateTimePicker"]}
              sx={{ pt: 0, overflow: "inherit" }}
            >
              <DateTimePicker
                label={label}
                value={dayjs(value)}
                minDateTime={minDateTime ? dayjs() : null}
                disabled={disableForm}
                sx={{ minWidth: "0 !important" }}
                onChange={(e) => onChangeInput(e)}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    error: !!error,
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        );
      case "datePicker":
        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={"en-gb"}
          >
            <DemoContainer
              components={["DatePicker"]}
              sx={{ pt: 0, overflow: "inherit" }}
            >
              <DatePicker
                label={label}
                value={dayjs(value)}
                disabled={disableForm}
                sx={{ minWidth: "0 !important" }}
                onChange={(e) => onChangeInput(e)}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    error: !!error,
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        );

      case "time":
        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={"en-gb"}
          >
            <DemoContainer
              components={["TimePicker"]}
              sx={{ pt: 0, overflow: "inherit" }}
            >
              <TimePicker
                label={label}
                value={dayjs(value, "HH:mm:ss")}
                disabled={disableForm}
                onAccept={(e) => onChangeInput(dayjs(e, "HH:mm:ss"))}
                onChange={(e) => onChangeInput(dayjs(e, "HH:mm:ss"))}
                sx={{ width: "100%" }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    error: !!error,
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        );

      case "richText":
        function cleanImageTags(htmlContent) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(htmlContent, "text/html");

          // Select all img tags
          const imgTags = doc.querySelectorAll("img");

          // Loop through each img tag and remove width and height attributes
          imgTags.forEach((img) => {
            img.removeAttribute("width");
            img.removeAttribute("height");
          });

          // Return the modified HTML
          return doc.body.innerHTML;
        }
        function uploadAdaptor(loader) {
          return {
            upload: async () => {
              try {
                const body = new FormData();
                const file = await loader.file;

                body.append("image", file);
                body.append("path", "textEditor");
                const pathUrl = "image";
                const config = {
                  method: "post",
                  url: `${process.env.API_BACKBONE}/${pathUrl}`,
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                  data: body,
                };

                const upload = await axios(config);
                const fileLocation = upload.data.payload.fileLocation;

                let responsiveImageHtml = `
                  <figure class="image">
                    <img src="${fileLocation}" style="aspect-ratio: inherit;" alt="Uploaded Image">
                  </figure>
                `;

                responsiveImageHtml = cleanImageTags(responsiveImageHtml);

                return {
                  default: fileLocation,
                  html: responsiveImageHtml,
                };
              } catch (error) {
                console.error("Image upload failed:", error);
                throw error;
              }
            },
          };
        }

        function uploadPlugin(editor) {
          editor.plugins.get("FileRepository").createUploadAdapter = (
            loader
          ) => {
            return new uploadAdaptor(loader);
          };
        }
        const handleEditorChange = (event, editor) => {
          let data = editor.getData();
          data = cleanImageTags(data); // Clean the data before handling it
          onChangeInput(data);
        };
        return (
          <Box
            mt={1}
            sx={{
              ".ck-editor": {
                border: error ? "1px solid red !important" : "",
              },".ck-editor__editable": {
                 minHeight: fieldHeight,
                resize: "vertical",
              },
              
            }}
          >
            <CKEditor
              editor={ClassicEditor}
              data={value}
              onBlur={handleEditorChange}
              config={{
                extraPlugins: [uploadPlugin],
                removePlugins: ["MediaEmbed", "EasyImage"],
              }}
              disabled={disableForm}
            />
          </Box>
        );

      case "airportSelect":
        return (
          <Grid container spacing={2}>
            {airportSiteSet.map((row, index) => {
              return (
                <Grid item xs={4} key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value[row.value]}
                        onChange={(e, newValue) => {
                          value[row.value] = newValue;
                          onChangeInput(value);
                        }}
                        name={row.value}
                        disabled={disableForm}
                        sx={{ color: error ? "#D32F2F" : "#0E1013" }}
                      />
                    }
                    label={row.label}
                    className={`${error ? "text-color-red" : ""}`}
                  />
                </Grid>
              );
            })}
          </Grid>
        );

      case "frequencySelect":
        const [frequency, setFrequency] = React.useState(
          value === 0 ? 0 : "everyDay"
        );
        const [days, setDays] = React.useState(value === 0 ? "" : value);

        const handleFrequencyChange = (newFrequency, daysValue = "") => {
          setFrequency(newFrequency);
          setDays(daysValue);
          if (newFrequency === "everyDay") {
            onChangeInput(daysValue);
          } else {
            onChangeInput(newFrequency);
          }
        };
        return (
          <Box>
            <RadioGroup
              value={frequency}
              onChange={(e) => handleFrequencyChange(e.target.value)}
            >
              <FormControlLabel
                value={0}
                control={<Radio disabled={disableForm} />}
                label="Always"
                onClick={() => handleFrequencyChange(0)}
              />
              <FormControlLabel
                value="everyDay"
                control={<Radio disabled={disableForm} />}
                label="Seen every (Day)"
              />
            </RadioGroup>

            {frequency === "everyDay" && (
              <TextField
                label="Number of days"
                type="number"
                value={days}
                variant="outlined"
                onChange={(e) => {
                  let value = e.target.value;
                  const numberValue = parseInt(value, 10);
                  if (value === "0" || numberValue < 1) {
                    value = "1"; // Set to 1 if the value is 0 or less
                  }
                  setDays(value);
                  onChangeInput(value);
                }}
                disabled={disableForm}
                error={error}
                InputProps={{ inputProps: { min: 1 } }}
              />
            )}
          </Box>
        );

      case "radioGroup":
        return (
          <FormControl>
            <RadioGroup defaultValue={value} row>
              {optionInput.map((row, index) => {
                return (
                  <FormControlLabel
                    value={row.value}
                    control={
                      <Radio
                        onChange={(e) => onChangeInput(e.target.value)}
                        disabled={disableForm}
                      />
                    }
                    label={row.label}
                    key={index}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        );

      case "color":
        return (
          <>
            <TextField
              label={label}
              fullWidth
              value={value}
              variant="outlined"
              onChange={(e) => {
                onChangeInput(e.target.value);
              }}
              disabled={disableForm}
              error={error}
              type="color"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      sx={{
                        width: 32,
                        height: 32,
                        bgcolor: value,
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </>
        );

      case "hide":
        return;
      default:
        break;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      {labelFor ? (
        <Typography>
          {labelText} {required && <span style={{ color: "#FF0000" }}>*</span>}
        </Typography>
      ) : (
        <Box sx={{ mt: 5 }}></Box>
      )}
      <Box my={2}>{checkInputType()}</Box>
      {labelForUnder && (
        <Typography sx={{ color: "#37444F", fontSize: "12px", mt: -2, mb: 2 }}>
          {labelTextUnder}
        </Typography>
      )}
    </Box>
  );
}
