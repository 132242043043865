import Nav from "./components/nav";
import { client } from "./config/apollo";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter, useLocation } from "react-router-dom";

function AppContent() {
  const location = useLocation();
  const isSetPasswordPage = location.pathname === "/set-password";
  const isReSetPasswordPage = location.pathname === "/reset-password";

  return (
    <>
     {!isSetPasswordPage && !isReSetPasswordPage && <Nav />}
      {/* ... other components or routes ... */}
    </>
  );
}

export default function Root(props) {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </ApolloProvider>
  );
}
